export default function Twitter({ className }: { className?: string }): JSX.Element {
	return (
		<svg
			width='30'
			height='30'
			viewBox='0 0 30 30'
			className={`fill-current ${className || ''}`}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M28 6.9385C27.043 7.3635 26.015 7.6495 24.936 7.7785C26.038 7.1185 26.883 6.0735 27.281 4.8275C26.251 5.4385 25.109 5.8825 23.893 6.1225C22.92 5.0855 21.534 4.4375 20 4.4375C17.054 4.4375 14.666 6.8265 14.666 9.7715C14.666 10.1895 14.714 10.5975 14.804 10.9865C10.371 10.7645 6.44102 8.6405 3.80902 5.4125C3.35102 6.2005 3.08802 7.1165 3.08802 8.0955C3.08802 9.9455 4.02902 11.5785 5.46002 12.5345C4.58602 12.5065 3.76302 12.2665 3.04402 11.8675C3.04402 11.8905 3.04402 11.9115 3.04402 11.9345C3.04402 14.5195 4.88202 16.6755 7.32302 17.1645C6.87602 17.2865 6.40402 17.3515 5.91702 17.3515C5.57402 17.3515 5.23902 17.3175 4.91402 17.2565C5.59302 19.3755 7.56302 20.9185 9.89702 20.9615C8.07202 22.3925 5.77202 23.2455 3.27202 23.2455C2.84202 23.2455 2.41702 23.2205 1.99902 23.1705C4.36002 24.6835 7.16302 25.5665 10.176 25.5665C19.988 25.5665 25.352 17.4385 25.352 10.3895C25.352 10.1585 25.347 9.9285 25.337 9.6995C26.38 8.9465 27.285 8.0075 28 6.9385Z' />
		</svg>
	);
}
