'use client';
import { Play, Pause, Loading } from '@/common/components/icons';
import { PlayerStatus } from '../models';

export default function PlayPauseButton({
	action,
	status,
	className = '',
	size = 'md',
	standalone = false,
}: {
	action: () => void;
	status: PlayerStatus;
	className?: string;
	size?: 'sm' | 'md' | 'lg';
	standalone?: boolean;
}): JSX.Element {
	const getSizeStyle = (s: string) => {
		switch (s) {
			case 'sm':
				return 'h-10 w-10';
			case 'md':
				return 'h-12 md:10 w-12 md:10';
			case 'lg':
				return 'md:h-14 md:w-14';
		}
	};

	return (
		<button
			className={`play-button flex flex-shrink-0 items-center justify-center rounded-full ${
				!standalone && 'bg-[#C70DD1] '
			}  text-white hover:bg-[#810b87] focus:border-2 focus:border-white focus:bg-[#f227fc] focus:outline-none ${className} ${getSizeStyle(
				size
			)}`}
			aria-label={status === PlayerStatus.PLAYING ? 'Pauser le média' : 'Jouer le média'}
			onClick={action}
		>
			{status === PlayerStatus.PLAYING && <Pause />}
			{status === PlayerStatus.PAUSED && <Play className={`${standalone ? 'h-[16px] w-[16px]' : 'h-[18px] w-[18px] '}`} />}
			{status === PlayerStatus.LOADING && <Loading />}
			{status === PlayerStatus.ERROR && 'X'}
		</button>
	);
}
