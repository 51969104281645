import { PlayerStatus } from '@/player/models';

const EMPTY_MEDIA = {
	title: 'Mock',
	host: 'Mock Host',
	url: '',
	artwork_src: '/artwork-live.jpg',
	duration: 0,
	status: PlayerStatus.PAUSED,
};

const RADIOSUDEST = {
	title: 'Le direct',
	host: 'Radio Sud Est',
	url: 'https://stream.vestaradio.com/RADIOSUDEST#.mp3',
	artwork_src: '/artwork-live.jpg',
	duration: Infinity,
	status: PlayerStatus.PAUSED,
};

const MOCK_MEDIAS = [
	{
		title: 'Nouvel Matinik du 20 Novembre 2021',
		host: 'Compressed FM',
		url: 'https://media.transistor.fm/ce44cefa/ac753943.mp3',
		artwork_src: '/artwork-live.jpg',
		duration: 0,
		status: PlayerStatus.PAUSED,
	},
	{
		title: 'Nouvel Matinik du 06 Novembre 2021',
		host: 'Claudy Lagier',
		url: 'https://media.transistor.fm/325a70c6/a4c19968.mp3',
		artwork_src: '/artwork-live.jpg',
		duration: 0,
		status: PlayerStatus.PAUSED,
	},

	{
		title: 'Getting Git',
		host: 'Compressed FM',
		url: 'https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/4a765c8e-18f6-4232-89b1-d6676f132fca/audio/c237f92b-b10b-4b65-9991-828ed98a8727/default_tc.mp3',
		artwork_src: '/mocks/medias/compressed-fm.jpg',
		duration: 0,
		status: PlayerStatus.PAUSED,
	},
	{
		title: 'SVGs FTW',
		host: 'Compressed FM',
		url: 'https://cdn.simplecast.com/audio/cae8b0eb-d9a9-480d-a652-0defcbe047f4/episodes/6a9416e5-e990-4f21-b70d-ed17dcb2bbda/audio/7938b143-7d3b-49f7-8c76-5d3c202f6f60/default_tc.mp3',
		artwork_src: '/mocks/medias/compressed-fm.jpg',
		duration: 1834.4,
		status: PlayerStatus.PAUSED,
	},
	{
		title: '420: The Pointer Cursor Debate',
		host: 'Design Details',
		url: 'https://pdst.fm/e/cdn.simplecast.com/audio/56e415f0-1911-44b3-9b1c-99551f7146c3/episodes/8e4db72e-d1ed-473a-a142-c032e469a3da/audio/a5d53507-3793-428f-8f1e-d4f64891c15a/default_tc.mp3',
		artwork_src: '/mocks/medias/design-details.jpg',
		duration: 1829.4,
		status: PlayerStatus.PAUSED,
	},
	{
		title: '409: Github Co-pilot is Gonna Take ur Job ',
		host: 'Syntax.FM',
		url: 'https://traffic.libsyn.com/syntax/Syntax_-_409.mp3',
		artwork_src: '/mocks/medias/syntax.jpg',
		duration: 1627.2,
		status: PlayerStatus.PAUSED,
	},
];

export { RADIOSUDEST, MOCK_MEDIAS, EMPTY_MEDIA };
