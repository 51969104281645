export default function Facebook({ className }: { className?: string }): JSX.Element {
	return (
		<svg
			width='30'
			height='30'
			className={`fill-current ${className || ''}`}
			viewBox='0 0 30 30'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M24 4H6C4.895 4 4 4.895 4 6V24C4 25.105 4.895 26 6 26H16V17H13V14H16V12.389C16 9.339 17.486 8 20.021 8C21.235 8 21.877 8.09 22.181 8.131V11H20.452C19.376 11 19 11.568 19 12.718V14H22.154L21.726 17H19V26H24C25.105 26 26 25.105 26 24V6C26 4.895 25.104 4 24 4Z' />
		</svg>
	);
}
