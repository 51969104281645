import Image from 'next/image';

type IArtwork = {
	url: string;
	size: 'thumbnail' | 'medium' | 'large';
	className?: string;
};
export default function Artwork({ url, size, className }: IArtwork): JSX.Element {
	let height, width;
	switch (size) {
		case 'thumbnail':
			height = width = 64;
			break;
		case 'medium':
			height = width = 100;

			break;
		case 'large':
			height = width = 100;
			break;

		default:
			break;
	}
	return (
		<div
			className={`flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg border border-solid border-gray-300 border-opacity-20 ${className}`}
		>
			<Image
				src={url || '/artwork-live'}
				height={height as any}
				width={width as any}
				className='rounded-lg'
				alt=''
				style={{
					maxWidth: '100%',
					height: 'auto',
					objectFit: 'cover',
				}}
			/>
		</div>
	);
}
