export default function PeopleIcon({ className }: { className?: string }): JSX.Element {
	return (
		<svg
			width='21'
			height='21'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			className={`fill-current ${className}`}
		>
			<rect x='0' fill='none' width='24' height='24' />
			<g>
				<path d='M16 8H8V6h8v2zm0 2H8v2h8v-2zm4-6v12l-6 6H6c-1.105 0-2-.895-2-2V4c0-1.105.895-2 2-2h12c1.105 0 2 .895 2 2zm-2 10V4H6v16h6v-4c0-1.105.895-2 2-2h4z' />
			</g>
		</svg>
	);
}
