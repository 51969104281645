type LoadingProps = {
	className?: string;
	height?: number | `${number}px`;
	width?: number | string;
	variant?: 'three-dots' | 'spinner';
};
export default function Loading({ className, height, width, variant = 'three-dots' }: LoadingProps): JSX.Element {
	if (variant === 'spinner') return <SpinnerLoading className={className} height={height} width={width} />;
	return <ThreeDotsLoading className={className} height={height} width={width} />;
}

export function SpinnerLoading({ className, height = '25px', width = '25px' }: LoadingProps): JSX.Element {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 100 100'
			xmlns='http://www.w3.org/2000/svg'
			className={`${className} stroke-current`}
		>
			<circle cx='50' cy='50' fill='none' strokeWidth='10' r='35' strokeDasharray='164.93361431346415 56.97787143782138'>
				<animateTransform
					attributeName='transform'
					type='rotate'
					repeatCount='indefinite'
					dur='1s'
					values='0 50 50;360 50 50'
					keyTimes='0;1'
				></animateTransform>
			</circle>
		</svg>
	);
}
export function ThreeDotsLoading({ className, height = 6, width = 24 }: LoadingProps): JSX.Element {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 120 30'
			xmlns='http://www.w3.org/2000/svg'
			className={`${className}  dark:fill-white `}
		>
			<circle cx='15' cy='15' r='15'>
				<animate
					attributeName='r'
					from='15'
					to='15'
					begin='0s'
					dur='0.8s'
					values='15;9;15'
					calcMode='linear'
					repeatCount='indefinite'
				/>
				<animate
					attributeName='fillOpacity'
					from='1'
					to='1'
					begin='0s'
					dur='0.8s'
					values='1;.5;1'
					calcMode='linear'
					repeatCount='indefinite'
				/>
			</circle>
			<circle cx='60' cy='15' r='9' fillOpacity='0.3'>
				<animate
					attributeName='r'
					from='9'
					to='9'
					begin='0s'
					dur='0.8s'
					values='9;15;9'
					calcMode='linear'
					repeatCount='indefinite'
				/>
				<animate
					attributeName='fillOpacity'
					from='0.5'
					to='0.5'
					begin='0s'
					dur='0.8s'
					values='.5;1;.5'
					calcMode='linear'
					repeatCount='indefinite'
				/>
			</circle>
			<circle cx='105' cy='15' r='15'>
				<animate
					attributeName='r'
					from='15'
					to='15'
					begin='0s'
					dur='0.8s'
					values='15;9;15'
					calcMode='linear'
					repeatCount='indefinite'
				/>
				<animate
					attributeName='fillOpacity'
					from='1'
					to='1'
					begin='0s'
					dur='0.8s'
					values='1;.5;1'
					calcMode='linear'
					repeatCount='indefinite'
				/>
			</circle>
		</svg>
	);
}

Loading.defaultProps = {
	className: '',
};
