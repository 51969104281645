'use client';

import { createContext, Dispatch, useContext, useReducer } from 'react';
import { IMedia, IPlayerAction, PlayerActionType, PlayerStatus } from '../models';
import { EMPTY_MEDIA } from '@/data/MEDIAS';

type IPlayer = { media: IMedia; status: PlayerStatus; volume: number; showPlayer: boolean };
type IPlayerDispatch = Dispatch<IPlayerAction>;
type PlayerHelpers = {
	setMediaAndPlay: (media: IMedia) => void;
	getMediaStatus: (media: IMedia) => PlayerStatus;
	setPlay: () => void;
	setPause: () => void;
	setLoading: () => void;
	setError: () => void;
	closePlayer: () => void;
	isPlaying: (media: IMedia) => boolean;
	isPaused: (media: IMedia) => boolean;
};

const initialPlayer: IPlayer = {
	media: EMPTY_MEDIA,
	status: PlayerStatus.PLAYING,
	showPlayer: false,
	volume: 1,
};

// REDUCER
export function playerReducer(state: IPlayer, action: IPlayerAction): IPlayer {
	switch (action.type) {
		case PlayerActionType.SET_MEDIA_AND_PLAY:
			return { ...state, media: action.media, status: PlayerStatus.PLAYING, showPlayer: true };
		case PlayerActionType.SET_PAUSE:
			return { ...state, status: PlayerStatus.PAUSED, media: { ...state.media, status: PlayerStatus.PAUSED } };
		case PlayerActionType.SET_PLAY:
			return { ...state, status: PlayerStatus.PLAYING, media: { ...state.media, status: PlayerStatus.PLAYING } };
		case PlayerActionType.SET_LOADING:
			return { ...state, status: PlayerStatus.LOADING, media: { ...state.media, status: PlayerStatus.LOADING } };
		case PlayerActionType.SET_ERROR:
			return { ...state, status: PlayerStatus.ERROR, media: { ...state.media, status: PlayerStatus.ERROR } };
		case PlayerActionType.CLOSE_PLAYER:
			return { ...state, showPlayer: false, media: EMPTY_MEDIA };
		default:
			throw new Error(`Unhandled action type`);
	}
}

// CONTEXT
const PlayerContext = createContext<IPlayer>(initialPlayer);
const PlayerDispatchContext = createContext<PlayerHelpers>({} as PlayerHelpers);

export function PlayerProvider({ children }: { children: React.ReactNode }): JSX.Element {
	const [player, dispatch] = useReducer(playerReducer, initialPlayer);
	const actions = getPlayerHelpers(player, dispatch);

	return (
		<PlayerContext.Provider value={player}>
			<PlayerDispatchContext.Provider value={actions}>{children}</PlayerDispatchContext.Provider>
		</PlayerContext.Provider>
	);
}

export function usePlayerContext(): [IPlayer, PlayerHelpers] {
	const context = useContext(PlayerContext);
	const dispatch = useContext(PlayerDispatchContext);
	return [context, dispatch];
}

// HELPERS
function getPlayerHelpers(player: IPlayer, dispatch: IPlayerDispatch): PlayerHelpers {
	const getMediaStatus = (media: IMedia) => {
		if (player.media.url === media.url) {
			return player.status;
		} else {
			return PlayerStatus.PAUSED;
		}
	};
	const setMediaAndPlay = (media: IMedia) => {
		if (player.media.url == media.url && player.media.status === PlayerStatus.PLAYING) {
			dispatch({ type: PlayerActionType.SET_PAUSE });
		} else if (player.media.url == media.url && player.media.status === PlayerStatus.PAUSED) {
			dispatch({ type: PlayerActionType.SET_PLAY });
		} else if (player.media.url == media.url && player.media.status === PlayerStatus.ERROR) {
			dispatch({ type: PlayerActionType.SET_ERROR });
		} else {
			dispatch({ type: PlayerActionType.SET_MEDIA_AND_PLAY, media });
		}
	};
	const isPlaying = (media: IMedia) => player.status === PlayerStatus.PLAYING || media.status === PlayerStatus.PLAYING;
	const isPaused = (media: IMedia) => player.status === PlayerStatus.PAUSED || media.status === PlayerStatus.PAUSED;
	const setPlay = () => dispatch({ type: PlayerActionType.SET_PLAY });
	const setPause = () => dispatch({ type: PlayerActionType.SET_PAUSE });
	const setLoading = () => dispatch({ type: PlayerActionType.SET_LOADING });
	const setError = () => dispatch({ type: PlayerActionType.SET_ERROR });
	const closePlayer = () => dispatch({ type: PlayerActionType.CLOSE_PLAYER });
	return { setMediaAndPlay, setPlay, setPause, setLoading, setError, closePlayer, getMediaStatus, isPlaying, isPaused };
}
