export default function FourDotsIcon({ className }: { className?: string }): JSX.Element {
	return (
		<svg
			width='21'
			height='21'
			viewBox='0 0 21 21'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			className={`fill-current ${className}`}
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.48684 0C1.1134 0 0 1.1134 0 2.48684V6.08847C0 7.46192 1.1134 8.57531 2.48684 8.57531H6.08847C7.46192 8.57531 8.57531 7.46192 8.57531 6.08847V2.48684C8.57531 1.1134 7.46192 0 6.08847 0H2.48684ZM2.48684 11.4765C1.1134 11.4765 0 12.5899 0 13.9634V17.565C0 18.9385 1.1134 20.0519 2.48684 20.0519H6.08847C7.46192 20.0519 8.57531 18.9385 8.57531 17.565V13.9634C8.57531 12.5899 7.46192 11.4765 6.08847 11.4765H2.48684ZM11.891 2.48684C11.891 1.1134 13.0044 0 14.3779 0H17.9795C19.3529 0 20.4663 1.1134 20.4663 2.48684V6.08847C20.4663 7.46192 19.3529 8.57531 17.9795 8.57531H14.3779C13.0044 8.57531 11.891 7.46192 11.891 6.08847V2.48684ZM14.3779 11.4765C13.0044 11.4765 11.891 12.5899 11.891 13.9634V17.565C11.891 18.9385 13.0044 20.0519 14.3779 20.0519H17.9795C19.3529 20.0519 20.4663 18.9385 20.4663 17.565V13.9634C20.4663 12.5899 19.3529 11.4765 17.9795 11.4765H14.3779Z'
			/>
		</svg>
	);
}
