'use client';
/* eslint-disable jsx-a11y/media-has-caption */
import Container from 'src/components/layout/Container';
import PlayPauseButton from '../PlayPauseButton';
import Artwork from '../Artwork';
import useAudio from '../../hooks/useAudio';
import { usePlayerContext } from '../../context';
import calculateTime from 'src/common/helpers/time';
import { PlayerStatus } from '../../models';

export default function Player({ close }: { close: () => void }): JSX.Element {
	const [{ media, status }] = usePlayerContext();
	const { setAudioRef, audio, togglePlay, currentTime, duration, seek } = useAudio();

	const closePlayer = (): void => {
		audio?.pause();
		close();
	};

	const getCurrentProgress = (): number => {
		if (duration === 0 || duration === Infinity) return 100;
		if (currentTime && duration) return (currentTime / duration) * 100;
		else return 0;
	};
	return (
		<Container
			id='player'
			className='player z-index-1 fixed right-0 left-0 bottom-5 mx-auto mr-auto rounded border border-solid border-gray-300 bg-gray-100 shadow-2xl dark:border-gray-700 dark:bg-gray-800'
		>
			<input
				type='range'
				min={0}
				max={100}
				value={getCurrentProgress()}
				className='player-seeker absolute top-0 w-full '
				onChange={(e) => seek(parseInt(e.target.value))}
				disabled={duration === 0 || duration === Infinity || !audio || status === PlayerStatus.ERROR}
			/>
			<audio ref={setAudioRef} src={media.url} preload='metadata' onCanPlay={() => audio?.play()} />
			<div className='flex items-center py-4 md:px-3'>
				<Artwork url={media.artwork_src} size='thumbnail' className='hidden md:flex' />
				<PlayPauseButton action={togglePlay} status={status} className='mx-3' />
				<div className='mr-6 w-full overflow-hidden'>
					<div className='flex justify-between '>
						<p className='text-sm'>{media.host}</p>
						<p className='text-sm text-gray-500 dark:text-gray-300'>
							{calculateTime(currentTime || 0)} / {calculateTime(duration || Infinity)}
						</p>
					</div>
					<p className='max-w-full truncate text-lg'>
						<strong>{media.title}</strong>
						{duration === Infinity && status === PlayerStatus.PLAYING && (
							<span className='ml-2 inline-block h-2 w-2 animate-pulse rounded-[50%] bg-red-500' />
						)}
					</p>
				</div>
			</div>

			<button
				onClick={closePlayer}
				className='absolute right-2 bottom-2 flex h-9 w-9 items-center justify-center rounded-full border border-gray-200 bg-gray-100 font-bold shadow-lg dark:border-gray-500 dark:bg-gray-800'
			>
				X
			</button>
		</Container>
	);
}

// TODO: Volume slider
// TODO: Fullscreen button
