export default function Pause({ className = '' }: { className?: string }): JSX.Element {
	return (
		<span className={`flex space-x-1 ${className}`}>
			<span className='block h-4 w-[3px] rounded-sm bg-white'></span>
			<span className='block h-4 w-[3px] rounded-sm bg-white'></span>
		</span>
	);
}

Pause.defaultProps = {
	className: '',
};
