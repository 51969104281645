export default function Container({
	className = '',
	children,
	id,
}: {
	className?: string;
	children: JSX.Element | JSX.Element[] | React.ReactNode;
	id?: string;
}): JSX.Element {
	return (
		<div id={id} className={`mx-auto w-11/12 max-w-screen-xl ${className || ''}`}>
			{children}
		</div>
	);
}
