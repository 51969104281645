import type { AppProps } from 'next/app';
import { SessionProvider } from 'next-auth/react';
import '@/styles/globals.css';
import { PlayerWrapper } from '@/player/components';
import { PlayerProvider } from '@/player/context';
import { ThemeProvider } from 'src/features/theme/context';
import { DefaultSeo } from 'next-seo';
import SEO from '@/lib/next-seo.config';
import { MDXProvider } from '@mdx-js/react';
import { H2, H3, H4, H5, H6, BR, P, UL, LI, OL, HR, IMG, BLOCKQUOTE } from '@/common/components/MDXElements';
import Analytics from '@/common/components/Analytics';
import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import '@/styles/react-quill.css';

const components = {
	h2: H2,
	h3: H3,
	h4: H4,
	h5: H5,
	h6: H6,
	p: P,
	ul: UL,
	li: LI,
	ol: OL,
	hr: HR,
	br: BR,
	blockquote: BLOCKQUOTE,
	img: IMG,
};

const queryClient = new QueryClient();

function RadioSudEst({
	Component,
	pageProps: { session, ...pageProps },
}: AppProps & { pageProps: { session: any } }): JSX.Element {
	return (
		<SessionProvider session={session}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider>
					<PlayerProvider>
						<MDXProvider components={components}>
							<DefaultSeo {...SEO} />
							<Analytics />
							<Toaster position='top-right' reverseOrder={false} />
							<Component {...pageProps} />
							<PlayerWrapper />
						</MDXProvider>
					</PlayerProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</SessionProvider>
	);
}
export default RadioSudEst;
