export { default as Pause } from './Pause';
export { default as Play } from './Play';
export { default as Sun } from './Sun';
export { default as Moon } from './Moon';
export { default as SystemComputer } from './Computer';
export { default as Loading } from './Loading';
export { default as Twitter } from './Twitter';
export { default as Facebook } from './Facebook';
export { default as YouTube } from './YouTube';
export { default as Mail } from './Mail';
export { default as Radio } from './Radio';
export { default as MapPin } from './MapPin';
export { default as Phone } from './Phone';
export { default as Logo } from './Logo';
export { default as InformationI } from './InformationI';
export { default as ArrowRight } from './ArrowRight';
export { default as FourDotsIcon } from './FourDotsIcon';
export { default as MicIcon } from './MicIcon';
export { default as PeopleIcon } from './PeopleIcon';
export { default as PagesIcon } from './PagesIcon';
export { default as UserIcon } from './UserIcon';
