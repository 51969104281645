'use client';
import { useCallback, useEffect, useState, useRef } from 'react';
import { PlayerStatus } from '../models';
import { usePlayerContext } from '../context';
import { toast } from 'react-hot-toast';

type IUseAudio = {
	audio: HTMLAudioElement | null;
	currentTime: number;
	duration: number;
	setAudioRef: (element: HTMLAudioElement) => void;
	togglePlay: () => void;
	seek: (position: number) => void;
};

const useAudio = (): IUseAudio => {
	const [{ media, status }, { setError, setLoading, setPause, setPlay, isPlaying, isPaused }] = usePlayerContext();
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const ongoingPromise = useRef<boolean>(false);
	const audioRef = useRef<HTMLAudioElement | null>(null);
	const audio = audioRef.current;

	const setAudioRef = useCallback((element: HTMLAudioElement) => {
		audioRef.current = element;
	}, []);

	useEffect(() => {
		if (audio !== null) {
			audio.onplay = setPlay;
			audio.onloadstart = setLoading;
			audio.onwaiting = setLoading;
			audio.onpause = () => {
				if (media.status === PlayerStatus.ERROR) setError();
				else setPause();
			};
			audio.onabort = () => (ongoingPromise.current = false);
			audio.onerror = () => {
				toast.error("Le média n'a pas pu être joué.");
				console.error("Le média n'a pas pu être joué.\n");
				setError();
			};
			audio.oncanplay = () => {
				// if (media.status === PlayerStatus.PLAYING || media.status === PlayerStatus.LOADING) {
				audio?.play();
				setPlay();
				// }
			};
		}
	}, [audio, media.status, setLoading, setPause, setPlay, setError]);

	useEffect(() => {
		let intervalId = window.setInterval(() => {
			if (audio === null) return clearInterval(intervalId);
			if (status === PlayerStatus.PAUSED) return clearInterval(intervalId);
			setCurrentTime(Math.round(audio.currentTime));
		}, 1000);
		return () => clearInterval(intervalId);
	}, [audio, status]);

	useEffect(() => {
		setCurrentTime(0);
		setDuration(media.duration);

		// Display the media info in the OS/Browser Media center
		if ('mediaSession' in navigator) {
			navigator.mediaSession.metadata = new MediaMetadata({
				title: media.title,
				artist: media.host,
				album: 'Radio Sud Est',
				artwork: [{ src: media.artwork_src, sizes: '512x512', type: 'image/png' }],
			});
		}
	}, [media.artwork_src, media.host, media.title, media.url, media.duration]);

	useEffect(() => {
		if (ongoingPromise.current) return;
		if (isPlaying(media) && audio?.paused) {
			const promise = audio?.play();
			promise
				?.catch(() => setError())
				?.finally(() => {
					ongoingPromise.current = false;
				});
			ongoingPromise.current = true;
		} else if (isPaused(media)) audio?.pause();
	}, [isPlaying, isPaused, media, audio, setError, ongoingPromise]);

	const togglePlay = () => {
		switch (status) {
			case PlayerStatus.PLAYING:
				audio?.pause();
				setPause();
				break;
			case PlayerStatus.PAUSED:
				audio?.play();
				setPlay();
				break;
			case PlayerStatus.ERROR:
				setError();
				break;
			default:
				setLoading();
				break;
		}
	};

	const seek = (position: number) => {
		if (audio && duration) {
			audio.currentTime = (position * duration) / 100;
			setCurrentTime(audio.currentTime);
		}
	};
	return {
		setAudioRef,
		audio,
		togglePlay,
		currentTime,
		duration,
		seek,
	};
};

export default useAudio;
