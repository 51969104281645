import { IMedia } from '../../medias/models/media';

export enum PlayerStatus {
	PLAYING = 'PLAYING',
	PAUSED = 'PAUSED',
	LOADING = 'LOADING',
	ERROR = 'ERROR',
}

export enum PlayerActionType {
	SET_MEDIA_AND_PLAY = 'SET_MEDIA_AND_PLAY',
	SET_PAUSE = 'SET_PAUSE',
	SET_PLAY = 'SET_PLAY',
	SET_LOADING = 'SET_LOADING',
	SET_ERROR = 'SET_ERROR',
	CLOSE_PLAYER = 'CLOSE_PLAYER',
}

type SetMediaAndPlayAction = { type: PlayerActionType.SET_MEDIA_AND_PLAY; media: IMedia };
type SetPauseAction = { type: PlayerActionType.SET_PAUSE };
type SetPlayAction = { type: PlayerActionType.SET_PLAY };
type SetLoadingAction = { type: PlayerActionType.SET_LOADING };
type SetErrorAction = { type: PlayerActionType.SET_ERROR };
type SetClosePlayerAction = { type: PlayerActionType.CLOSE_PLAYER };

type SetStatusAction = SetPlayAction | SetPauseAction | SetLoadingAction | SetErrorAction | SetClosePlayerAction;

export type IPlayerAction = SetMediaAndPlayAction | SetStatusAction;
