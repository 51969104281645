import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export default function calculateTime(secs: number): string {
	if (secs === Infinity) return '∞';
	if (isNaN(secs)) return '--:--';

	const hours = Math.floor(secs / 3600);
	const returnedHours = hours > 0 ? `${hours}:` : '';
	const minutes = Math.floor((secs - hours * 3600) / 60);
	const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
	const seconds = Math.floor(secs % 60);
	const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
	return `${returnedHours}${returnedMinutes}:${returnedSeconds}`;
}

export type DateFormatVariant = 'dd MMMM' | 'dd MMMM yyyy' | 'dd MMMM yyyy à HH:mm';

export function formatTime(date: string | Date = '', variant: DateFormatVariant): string {
	try {
		return format(new Date(date), variant, { locale: fr });
	} catch (e) {
		return '';
	}
}
