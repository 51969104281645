import Link from 'next/link';
import Image from 'next/image';

type ILogo = {
	className?: string;
	alt?: string;
	height?: number;
};

export default function Logo({ className, withLink = true, alt, height }: ILogo & { withLink?: boolean }): JSX.Element {
	return withLink ? (
		<LogoWithLink className={className} alt={alt} height={height} />
	) : (
		<BaseLogo className={className} alt={alt} />
	);
}

function LogoWithLink({ className, alt, height }: ILogo): JSX.Element {
	return (
		<Link href='/' passHref>
			<BaseLogo className={className} alt={alt} height={height} />
		</Link>
	);
}

function BaseLogo({ className = '', alt = "Naviguer vers la page d'accueil", height = 71 }: ILogo): JSX.Element {
	return (
		<Image
			src='/logo.png'
			alt={alt}
			width={height * 2.155}
			height={height}
			placeholder='blur'
			blurDataURL='/logo.png'
			className={className}
			style={{
				maxWidth: '100%',
				height: 'auto',
			}}
		/>
	);
}
